import getStaticSeo from 'constants/staticSeo';
import { CreatorBrand, LandrBrand } from 'constants/brands';
import { LanguageToLocale, Languages, RoutePaths } from 'types/enums';
import React, { useCallback } from 'react';
import { Helmet } from 'react-helmet';
import { AnalyticsLanguage } from '@landr/core';
import { useToLocalizedPath } from 'hooks/useToLocalizedPath/useToLocalizedPath';
import { useLocation, useMatch } from 'react-router-dom';
import { analytics } from 'utils/analytics';
import { i18n } from 'utils/setupTranslations';
import { useErrorBoundaryContext } from 'contexts/ErrorBoundaryContext/ErrorBoundaryContext';
import { useAlternateLinks } from './useAlternateLinks';
import { getWebsiteSchema } from './getWebsiteSchema';
import { getCanonicalUrl } from './getCanonicalUrl';

export interface PageMetadataProps {
    title?: string;
    description?: string;
    imageUrl?: string;
    withGoogleSearch?: boolean;
    withBrandTitlePrefix?: boolean;
}

const PageMetadata = ({
    title,
    description,
    imageUrl,
    withGoogleSearch,
    withBrandTitlePrefix = false,
}: PageMetadataProps) => {
    const location = useLocation();
    const { error } = useErrorBoundaryContext();
    const staticSeo = getStaticSeo();
    const toLocalizedPath = useToLocalizedPath();

    const { localeLinks, xDefaultLink } = useAlternateLinks();

    const isBeatMakerHomePage = useMatch(
        toLocalizedPath(`/${RoutePaths.CreatorBeatMaker}`),
    );

    const isBeatMakerSharedPage = useMatch(
        toLocalizedPath(`/${RoutePaths.CreatorBeatMaker}/:guid`),
    );

    const isCreatorBrandTitle = isBeatMakerHomePage && !isBeatMakerSharedPage;
    const brandTitle = isCreatorBrandTitle
        ? `${CreatorBrand} by ${LandrBrand}`
        : `${LandrBrand} Samples`;

    /**
     * On FF cleanup, I would suggest to move these default back to `PageMetadata.defaultProps`
     */
    const seoTitle = `${title || staticSeo.default.title}`;
    const seoTitleBranded = withBrandTitlePrefix
        ? `${brandTitle}: ${seoTitle}`
        : `${seoTitle} | ${brandTitle}`;

    const seoDescription = description || staticSeo.default.description;
    const seoImageUrl = imageUrl || staticSeo.default.imageUrl;
    const ogLocale = LanguageToLocale[i18n.locale as Languages];

    const handleLocationChange = useCallback(() => {
        analytics.page(i18n.locale as AnalyticsLanguage);
    }, [location]);

    const canonicalUrl = getCanonicalUrl(window.location.href);

    return (
        <Helmet onChangeClientState={handleLocationChange}>
            {/* Basic metadata */}
            <title>{seoTitleBranded}</title>
            <meta name="description" content={seoDescription} />
            {error?.variant === 'page' && (
                // do we need a prerender-status-code 200 ?
                <meta name="prerender-status-code" content="404" />
            )}
            <link rel="canonical" href={canonicalUrl} />
            {xDefaultLink}
            {localeLinks}
            {/* OpenGraph tags */}
            <meta
                property="og:url"
                content={window.location.origin + location.pathname}
            />
            <meta property="og:title" content={seoTitleBranded} />
            <meta property="og:description" content={seoDescription} />
            <meta property="og:type" content="website" />
            <meta property="og:image" content={seoImageUrl} />
            <meta property="og:locale" content={ogLocale} />
            <meta property="og:site_name" content="LANDR Samples" />
            {/* Twitter Card tags */}
            <meta name="twitter:title" content={seoTitleBranded} />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:creator" content="@LANDR_music" />
            <meta name="twitter:description" content={seoDescription} />
            <meta name="twitter:image" content={seoImageUrl} />
            {/* Corporate Contacts Information for Google Search */}
            <script type="application/ld+json">
                {JSON.stringify(
                    getWebsiteSchema({
                        withGoogleSearch,
                    }),
                )}
            </script>
        </Helmet>
    );
};

export default React.memo(PageMetadata);
