import { ModalsEnum } from 'types/enums';
import {
    InteractionSource,
    ModalName,
} from 'utils/analytics/AnalyticsV2Service.generated';

export const modalInstrumentationV2 = {
    [ModalsEnum.freeUserFreeDownloadUpsell]: {
        modalName: ModalName.UpsellModalBasicDownload,
        interactionSource: InteractionSource.AssetAction,
    },
    [ModalsEnum.freeUserPaidDownloadUpsell]: {
        modalName: ModalName.UpsellModalFreeUserPaidDownload,
        interactionSource: InteractionSource.Automatic,
    },
    [ModalsEnum.paidUserPaidDownloadUpsell]: {
        modalName: ModalName.UpsellModalPaidUserPaidDownload,
        interactionSource: InteractionSource.Automatic,
    },
    [ModalsEnum.creditsWarning]: undefined,
    [ModalsEnum.removeSamplesFromCollection]: undefined,
    [ModalsEnum.deleteCollectionModal]: undefined,
    [ModalsEnum.createCollectionModal]: undefined,
    [ModalsEnum.editCollectionModal]: undefined,
    [ModalsEnum.poorStandingOrSuspended]: undefined,
    [ModalsEnum.keyboardCheatSheet]: undefined,
    [ModalsEnum.sweetenerUpsellModal]: undefined,
    [ModalsEnum.planPaused]: undefined,
    [ModalsEnum.activateTrialSubscription]: undefined,
    [ModalsEnum.trialUpsellModal]: {
        modalName: ModalName.TrialSelection,
        interactionSource: InteractionSource.Automatic,
    },
};
