import 'abortcontroller-polyfill/dist/polyfill-patch-fetch';
import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { setupLandrDevTools } from '@landr/core';
import { ApplicationEnum } from '@landr/core.models';
import { BrowserRouter } from 'react-router-dom';
import ErrorBoundary from 'error/components/ErrorBoundary';
import setupPolyfills from 'utils/polyfills';
import MetadataRouter from 'components/MetadataRouter';
import { CONFIG } from 'utils/config';
import ApolloProvider from 'contexts/ApolloContext/ApolloContext';
import ExecutionEnvironmentProvider from 'contexts/ExecutionEnvironmentContext/ExecutionEnvironmentContext';
import CreatorSessionProvider from 'contexts/CreatorSessionContext/CreatorSessionContext';
import SweetenerUpsellModalProvider from 'modals/SweetenerUpsellModal/SweetenerUpsellModalContext';
import { AuthenticationProvider } from 'components/AuthenticationProvider/AuthenticationProvider';
import ActivePlayerProvider from 'contexts/ActivePlayerContext/ActivePlayerContext';
import ReactPlayerProvider from 'contexts/ReactPlayerContext/ReactPlayerContext';
import OnboardingProvider from 'contexts/OnboardingContext/OnboardingContext';
import NotificationsProvider from 'contexts/NotificationsContext/NotificationsContext';
import SelectedSamplesProvider from 'contexts/SelectedSamplesContext/SelectedSamplesContext';
import CreatorContextProvider from 'controllers/business/Creator/Creator.controller';
import ClientUpdateProvider from 'contexts/ClientUpdateContext/ClientUpdateContext';
import ModalProvider from 'contexts/ModalContext/ModalContext';
import SessionStorageProvider from 'contexts/SessionStorageContext/SessionStorageContext';
import PurchaseControllerProvider from 'contexts/PurchaseControllerContext/PurchaseControllerContext';
import UserCreditsProvider from 'contexts/UserCreditsContext/UserCreditsContext';
import PricingProviderWithToken from 'components/PricingProviderWithToken/PricingProviderWithToken';
import CollectionModalContextProvider from 'modals/CollectionModal/CollectionModalContext';
import ErrorBoundaryProvider from 'contexts/ErrorBoundaryContext/ErrorBoundaryContext';
import UserProfileProvider from 'contexts/UserProfileContext/UserProfileContext';
import MilestonesProvider from 'controllers/business/Milestones/Milestones.controller';
import ApiFiltersProvider from 'contexts/ApiFiltersContext/ApiFiltersContext';
import SidebarLayoutProvider from 'controllers/SidebarLayout/SidebarLayout';
import GradientProvider from 'controllers/GradientController/GradientController';
import { featureFlagService } from 'helpers/featureFlagsService/feature-flag-service';
import { I18nProvider } from 'contexts/I18nProvider/I18nProvider';
import SubscriptionsAndAllowedFeaturesProvider from 'contexts/SubscriptionsAndAllowedFeaturesContext/SubscriptionsAndAllowedFeaturesContext';
import GatedAppProvider from 'contexts/FreemiumGatingContext/FreemiumGatingContext';
import FreeUserFreeDownloadUpsellModalProvider from 'modals/UpsellModals/FreeUserFreeDownloadUpsellModalContext';
import { i18n } from 'utils/setupTranslations';
import { lazyWithRetries } from 'utils/lazyWithRetries';
import { APP_VERSION } from './constants';
import AppStyleProvider from './AppGlobalStyle';

const App = lazyWithRetries(() => import('./App'));

const providers = [
    {
        component: ErrorBoundaryProvider,
        props: { key: 'ErrorBoundaryProvider' },
    },
    {
        component: ErrorBoundary,
        props: {
            variant: 'global',
            displayName: 'Root',
            key: 'ErrorBoundary',
        },
    },
    { component: Suspense, props: { fallback: null, key: 'Suspense' } },
    { component: BrowserRouter, props: { key: 'BrowserRouter' } },
    {
        component: ExecutionEnvironmentProvider,
        props: { key: 'ExecutionEnvironmentProvider' },
    },
    {
        component: AuthenticationProvider,
        props: { key: 'AuthenticationProvider' },
    },
    {
        component: I18nProvider,
        props: {
            i18n,
            key: 'I18nProvider',
        },
    },
    {
        component: PricingProviderWithToken,
        props: { key: 'PricingProviderWithToken' },
    },
    {
        component: SessionStorageProvider,
        props: { key: 'SessionStorageProvider' },
    },
    {
        component: ApolloProvider,
        props: { key: 'ApolloProvider' },
    },
    { component: AppStyleProvider, props: { key: 'AppStyleProvider' } },
    { component: MetadataRouter, props: { key: 'MetadataRouter' } },
    {
        component: NotificationsProvider,
        props: { key: 'NotificationsProvider' },
    },
    { component: ModalProvider, props: { key: 'ModalProvider' } },
    {
        component: FreeUserFreeDownloadUpsellModalProvider,
        props: { key: 'FreeUserFreeDownloadUpsellModalProvider' },
    },
    {
        component: SubscriptionsAndAllowedFeaturesProvider,
        props: { key: 'SubscriptionsAndAllowedFeaturesProvider' },
    },
    {
        component: GatedAppProvider,
        props: { key: 'GatedAppProvider' },
    },
    {
        component: UserProfileProvider,
        props: { key: 'UserProfileProvider' },
    },
    {
        component: MilestonesProvider,
        props: { key: 'MilestonesProvider' },
    },
    { component: UserCreditsProvider, props: { key: 'UserCreditsProvider' } },
    {
        component: CreatorSessionProvider,
        props: { key: 'CreatorSessionProvider' },
    },
    {
        component: PurchaseControllerProvider,
        props: { key: 'PurchaseControllerProvider' },
    },
    {
        component: SidebarLayoutProvider,
        props: { key: 'SidebarLayoutProvider' },
    },
    { component: ReactPlayerProvider, props: { key: 'ReactPlayerProvider' } },
    { component: ActivePlayerProvider, props: { key: 'ActivePlayerProvider' } },
    {
        component: SweetenerUpsellModalProvider,
        props: { key: 'SweetenerUpsellModalProvider' },
    },
    {
        component: SelectedSamplesProvider,
        props: { key: 'SelectedSamplesProvider' },
    },
    { component: OnboardingProvider, props: { key: 'OnboardingProvider' } },
    {
        component: CreatorContextProvider,
        props: { key: 'CreatorContextProvider' },
    },
    { component: ClientUpdateProvider, props: { key: 'ClientUpdateProvider' } },
    {
        component: CollectionModalContextProvider,
        props: { key: 'CollectionModalContextProvider' },
    },
    {
        component: ApiFiltersProvider,
        props: { key: 'ApiFiltersProvider' },
    },
    {
        component: GradientProvider,
        props: { key: 'GradientProvider' },
    },
];

const renderRoot = () =>
    providers.reduceRight(
        (acc, next) =>
            React.createElement(
                next.component as React.FC<{ key: string }>,
                {
                    ...next.props,
                },
                [acc],
            ),
        React.createElement(App, { key: 'App' }, null),
    );

const container = document.getElementById('root');
const root = createRoot(container!);

// Load feature flag
featureFlagService.load();
setupPolyfills();
setupLandrDevTools(ApplicationEnum.SamplesMarketplace, APP_VERSION, CONFIG);
root.render(renderRoot());
