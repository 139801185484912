import { Context, useContext } from 'react';

/**
 * Creates a hook to safely consume a React context with proper error handling
 * @param context The React context to consume
 * @param contextName The name of the context for error messages
 * @returns A hook that provides the context value with type safety
 */
export function createContextHook<T>(context: Context<T>, contextName: string) {
    return function useContextWithError(): T {
        const contextValue = useContext(context);

        if (!contextValue) {
            throw new Error(
                `use${contextName} must be used within a ${contextName}Provider`,
            );
        }

        return contextValue;
    };
}
