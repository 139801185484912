import React, {  useCallback, useState, useRef } from 'react';
import { useDisclosure } from '@landr/maestro';
import { neverop } from 'utils/noop';
import { useWasTrueOnce } from 'hooks/useWasTrueOnce/useWasTrueOnce';
import { createContextHook } from 'helpers/createContextHook/createContextHook';

type SidebarLayoutType = {
    toggleSidebar: () => void;
    openSidebar: () => void;
    isSidebarOpen: boolean;
    hasSidebarOpenOnce: boolean;
    sidebarRef: React.Ref<HTMLDivElement | undefined> | null;
    scrollToTop: () => void;
    defaultSelectedTab: FiltersTabs | undefined;
    setDefaultSelectedTab: React.Dispatch<
        React.SetStateAction<FiltersTabs | undefined>
    >;
};

export const DefaultSidebarLayoutContext: SidebarLayoutType = {
    toggleSidebar: neverop('SidebarLayoutContext', 'toggleSidebar'),
    openSidebar: neverop('SidebarLayoutContext', 'openSidebar'),
    isSidebarOpen: false,
    hasSidebarOpenOnce: false,
    sidebarRef: null,
    scrollToTop: neverop('SidebarLayoutContext', 'scrollToTop'),
    defaultSelectedTab: undefined,
    setDefaultSelectedTab: neverop(
        'SidebarLayoutContext',
        'setDefaultSelectedTab',
    ),
};

export const SidebarLayoutContext = React.createContext<SidebarLayoutType>(
    DefaultSidebarLayoutContext,
);

export const useSidebarLayoutContext = createContextHook(
    SidebarLayoutContext,
    'SidebarLayout',
);

export const enum FiltersTabs {
    'Instruments' = 'tab-instruments',
    'Genres' = 'tab-genres',
    'Sfxs' = 'tab-sfsx',
    'BpmAndKeys' = 'tab-bpm-and-keys',
}

export const useSidebarLayoutProvider = () => {
    const { isOpen: isSidebarOpen, onToggle, onOpen } = useDisclosure();
    const hasSidebarOpenOnce = useWasTrueOnce(isSidebarOpen);

    const [defaultSelectedTab, setDefaultSelectedTab] = useState<
        FiltersTabs | undefined
    >(undefined);

    const sidebarRef = useRef<HTMLDivElement | undefined>(undefined);

    const scrollToTop = useCallback(() => {
        if (sidebarRef.current) {
            sidebarRef.current.scrollTo(0, 0);
        }
    }, [sidebarRef]);

    return {
        toggleSidebar: onToggle,
        openSidebar: onOpen,
        isSidebarOpen,
        hasSidebarOpenOnce,
        sidebarRef,
        scrollToTop,
        defaultSelectedTab,
        setDefaultSelectedTab,
    };
};

export const SidebarLayoutProvider = ({
    children,
}: {
    children: React.ReactNode;
}): React.ReactElement => {
    const context = useSidebarLayoutProvider();

    return (
        <SidebarLayoutContext.Provider value={context}>
            {children}
        </SidebarLayoutContext.Provider>
    );
};

export default SidebarLayoutProvider;
