// Basic feature flag service
// This service can only work with anonymous users as the authentication token won't be passed, we don't want to wait for it
// Based on LANDR.Web.Projects/apps/root-app/src/sharedLibs/@landr/root-feature-flags.ts
import { EventIds } from 'constants/eventIds';
import {
    areDevToolsActive,
    FlagUpdatePayload,
    getCachedForcedFlags,
    sendToDevTools,
    subscribeFromDevTools,
} from '@landr/core';
import { ApplicationEnum } from '@landr/core.models';
import { auth } from 'utils/auth';
import { CONFIG } from 'utils/config';
import { FeatureFlag } from 'types/enums';
import { Observer } from 'utils';
import { fetchErrorHandler } from 'helpers/fetchErrorHandler/fetchErrorHandler';

export type ActiveFlags = {
    [k in FeatureFlag]: boolean;
};

export const defaultFeatureFlags: ActiveFlags = {
    [FeatureFlag.Unboxed]: false,
};

export class FeatureFlagService {
    private featureFlags: ActiveFlags = defaultFeatureFlags;
    private loadPromise: Promise<boolean> | null = null;

    public observers = new Set<Observer<Partial<ActiveFlags>>>();

    public subscribe(observer: Observer<Partial<ActiveFlags>>): void {
        this.observers.add(observer);
    }

    public unsubscribe(observer: Observer<Partial<ActiveFlags>>): void {
        this.observers.delete(observer);
    }

    private broadcast(data: Partial<ActiveFlags>): void {
        this.observers.forEach((observer) => {
            observer.callback(data);
        });
    }

    load(): Promise<boolean> {
        if (!this.loadPromise) {
            const cachedForcedFlags = getCachedForcedFlags(
                ApplicationEnum.SamplesMarketplace,
            );

            const abortController = new AbortController();

            this.loadPromise = auth.getAccessToken().then(async (token) => {
                try {
                    const url = CONFIG.VITE_MS_FEATURE_FLAG as string;
                    const options = {
                        method: 'GET',
                        headers: {
                            authorization: `Bearer ${token}`,
                        },
                        signal: abortController.signal,
                    };

                    const response = await fetch(url, options);
                    const flagsFromMS = await response.json();

                    if (areDevToolsActive()) {
                        sendToDevTools('set-state', {
                            name: ApplicationEnum.SamplesMarketplace,
                            featureFlags: {
                                ...defaultFeatureFlags,
                                ...flagsFromMS,
                            },
                            forcedFlags: cachedForcedFlags,
                        });

                        subscribeFromDevTools(
                            'feature-flags-updated',
                            async ({
                                payload,
                            }: {
                                payload: FlagUpdatePayload;
                            }) => {
                                this.featureFlags = {
                                    ...this.featureFlags,
                                    ...payload.forcedFlags,
                                };

                                this.broadcast(this.featureFlags);
                            },
                        );
                    }

                    this.featureFlags = {
                        ...defaultFeatureFlags,
                        ...flagsFromMS,
                        ...cachedForcedFlags,
                    };

                    this.broadcast(this.featureFlags);
                } catch (error) {
                    fetchErrorHandler({ 
                        errorMessage:  'Failed to get feature flag',
                        eventId: EventIds.RequestFlagsFailed,
                        error
                    });
                }

                return true;
            });

            // Clean up the abort controller when the promise is resolved or rejected
            this.loadPromise.finally(() => {
                abortController.abort();
            });
        }

        return this.loadPromise;
    }

    get(): ActiveFlags {
        return this.featureFlags;
    }
}

export const featureFlagService = new FeatureFlagService();
