import { log } from 'utils/log';

type ErrorCategory = 'abort' | 'network' | 'cors' | 'server' | 'unknown';

const categorizeError = (error: unknown): ErrorCategory => {
    // User-initiated aborts (can be ignored)
    if (error instanceof DOMException && error.name === 'AbortError') {
        return 'abort';
    }

    // TypeError without response
    if (error instanceof TypeError) {
        // Navigation/cleanup aborts
        if (error.message === 'Failed to fetch' && document.visibilityState === 'hidden') {
            return 'abort';
        }

        // CORS issues
        if (error.message.includes('CORS')) {
            return 'cors';
        }

        // Network issues need attention
        return 'network';
    }

    // Server errors will have status codes
    if (error instanceof Response) {
        return 'server';
    }

    return 'unknown';
};

export const fetchErrorHandler = ({error, errorMessage, eventId}: {error: unknown, errorMessage: string, eventId: number}): boolean => {
    const category = categorizeError(error);

    // Only abort errors can be safely ignored
    if (category === 'abort') {
        return false;
    }

    // Log other errors as actual errors
    log.error(
        errorMessage,
        eventId,
        error,
        {
            category,
            visibility: document.visibilityState,
            online: navigator.onLine
        }
    );

    return true;
};