import React, { useContext } from 'react';
import { useSubscriptionsAndAllowedFeaturesProvider } from 'contexts/SubscriptionsAndAllowedFeaturesContext/SubscriptionsAndAllowedFeaturesContext';
import { useAuthentication } from 'components/AuthenticationProvider/AuthenticationProvider';

export type FreemiumGatingContextType = {
    isAppGated: boolean;
    isLoading: boolean;
};

export const defaultFreemiumGatingContext: FreemiumGatingContextType = {
    isAppGated: false,
    isLoading: false,
};

export const FreemiumGatingContext =
    React.createContext<FreemiumGatingContextType>(
        defaultFreemiumGatingContext,
    );

export const useFreemiumGatingContext = (): FreemiumGatingContextType =>
    useContext(FreemiumGatingContext);

export const FreemiumGatingProvider = ({
    children,
}: {
    children: React.ReactNode;
}): React.ReactElement => {
    const { isAuthenticated, isLoading } = useAuthentication();
    const { upsellingAllowed, hasFetched } =
        useSubscriptionsAndAllowedFeaturesProvider();

    const context = {
        isAppGated: !isAuthenticated || upsellingAllowed,
        isLoading: isLoading || !hasFetched,
    };

    return (
        <FreemiumGatingContext.Provider value={context}>
            {children}
        </FreemiumGatingContext.Provider>
    );
};

export default FreemiumGatingProvider;
