import { CONFIG } from 'utils';
import { WebsiteSchema } from './type';

export const getWebsiteSchema = ({
    withGoogleSearch,
}: {
    withGoogleSearch?: boolean;
}) => {
    const schema: WebsiteSchema = {
        '@context': 'http://schema.org',
        '@type': 'WebSite',
        name: 'LANDR',
        image: `${CONFIG.VITE_GUESTSITE_URL}/img/logo-landr.png`,
        url: 'https://samples.landr.com/',
        sameAs: [
            'https://twitter.com/LANDR_music',
            'https://www.facebook.com/LANDRmusic',
            'https://www.youtube.com/user/LANDRmastering',
            'https://www.linkedin.com/company/mixgeni-us',
        ],
        publisher: {
            '@type': 'Organization',
            name: 'LANDR',
            url: CONFIG.VITE_GUESTSITE_URL,
            telephone: '+1 514-840-9700',
            email: 'mailto:support@landr.com',
            image: `${CONFIG.VITE_GUESTSITE_URL}/img/logo-landr.png`,
            logo: `${CONFIG.VITE_GUESTSITE_URL}/img/logo-landr.png`,
            address: {
                '@type': 'PostalAddress',
                addressLocality: 'Montreal',
                addressRegion: 'QC',
                addressCountry: 'CA',
                postalCode: 'H3B 4L4',
                streetAddress: '1001 Boulevard Robert-Bourassa Suite 2100',
            },
        },
    };

    if (withGoogleSearch) {
        schema['potentialAction'] = {
            '@type': 'SearchAction',
            target: {
                '@type': 'EntryPoint',
                urlTemplate:
                    'https://samples.landr.com/browse?textsearch={search_query_input}',
            },
            'query-input': 'required name=search_query_input',
        };
    }

    return schema;
};
