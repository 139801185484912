import { EventIds } from 'constants/eventIds';
import { RetryLink } from '@apollo/client/link/retry';
import { Operation } from '@apollo/client/link/core';
import { log } from 'utils/log';

const InitialDelayInMs = 500;
const MaxAttempts = 3;
// Exponential backoff doubles the delay for each attempt.
// We add the initial delay to prevent last attempt rejection.
const MaxDelay = Math.pow(2, MaxAttempts) * InitialDelayInMs + InitialDelayInMs;

// Which graphql operation we want to retry on network error
export const retryableOperations = new Set([
    'AccountBalanceSummary',
    'ChartsTopPacks',
    'ContentGroup',
    'GetUserRecentSearch',
    'MerchZone',
    'Milestones',
    'Pack',
    'PaginatedDownloadedPacksAndRepacks',
    'PaginatedFavoritedPacksAndRepacks',
    'PaginatedPacks',
    'PaginatedPackSamples',
    'PaginatedPacksAndRepacks',
    'PaginatedRecommendedSamples',
    'PaginatedRepackSamples',
    'PaginatedSamples',
    'Repack',
    'Sample',
    'SamplesCostPreview',
    'SampleStream',
    'UserAgreements',
    'UserCredits',
    'UserHasSubscription',
    'UserProfile',
]);

const isUserNetworkError = (error: any): boolean => {
    // Check for navigator.onLine first
    if (!!error?.networkError || !navigator.onLine) {
        return true;
    }

    const errorMessage = error?.message?.toLowerCase() || '';

    // Common browser network error messages
    const userNetworkErrors = [
        'failed to fetch',
        'networkerror',
        'network error',
        'the internet connection appears to be offline',
        'net::err_internet_disconnected',
        'net::err_connection_timed_out',
        'net::err_network_changed',
    ];

    return userNetworkErrors.some((msg) => errorMessage.includes(msg));
};

const isAbortError = (error: any): boolean => {
    return (
        (error instanceof DOMException && error.name === 'AbortError') ||
        error?.name === 'AbortError' ||
        error?.message?.includes('aborted') ||
        error?.message?.includes('canceled')
    );
};

const isServerError = (error: any): boolean => {
    // Check for specific HTTP status codes
    const statusCode =
        error?.networkError?.statusCode ||
        error?.response?.status ||
        error?.statusCode;

    // Server errors are typically 500-599
    if (statusCode >= 500) {
        return true;
    }

    // Check for timeout
    const errorMessage = error?.message?.toLowerCase() || '';

    return (
        errorMessage.includes('timeout') ||
        errorMessage.includes('econnrefused') ||
        errorMessage.includes('502') ||
        errorMessage.includes('503') ||
        errorMessage.includes('504')
    );
};

export const retryIf = (error: any, operation: Operation): boolean => {
    const isRetryableOperation = retryableOperations.has(
        operation.operationName,
    );

    // Only retry if it's a user network error and retryable operation
    if (
        !isRetryableOperation ||
        isAbortError(error) ||
        isServerError(error) ||
        !isUserNetworkError(error)
    ) {
        return false;
    }

    const { headers } = operation.getContext();

    log.warn(
        `[Operation]: ${operation?.operationName}, [Error]: ${error}`,
        EventIds.ApolloNetworkError,
        error,
        {
            operationName: operation?.operationName,
            variables: operation?.variables,
            correlationId: headers?.['X-CorrelationId'],
        },
    );

    return true;
};

const delay: RetryLink.Options['delay'] = {
    initial: InitialDelayInMs,
    max: MaxDelay,
    jitter: true,
};

const attempts: RetryLink.Options['attempts'] = {
    max: MaxAttempts,
    retryIf,
};

export const retryLink = new RetryLink({ delay, attempts });
