import { useEffect, useState } from 'react';

export const useWasTrueOnce = (condition: boolean) => {
    const [wasTrueOnce, setWasTrueOnce] = useState(false);

    useEffect(() => {
        if (condition) {
            setWasTrueOnce(true);
        }
    }, [condition]);

    return wasTrueOnce;
};
